<template>
  <c-card :title="`Ganti Password ${name}`">
    <template slot="action">
      <c-button v-if="cP(10)" @click="$refs.myform.onSubmit()" :loading="isloading">Simpan</c-button>
    </template>
    <b-row>
      <b-col v-if="cP(10)" sm="12" md="6">
        <b-card>
          <c-form ref="myform" @submit="submitForm">
            <form-input :disabled="is_disable" type="password" label="Password Lama" :rules="{required: !is_disable}" v-model="old_password"></form-input>
            <form-input type="password" label="Password Baru" :rules="{required: true, confirmed: 'confirmationPass'}" v-model="password"></form-input>
            <form-input type="password" label="Ulangi Password Baru" :rules="{required: true}" vid="confirmationPass" v-model="confirmationPass"></form-input>
            <button hidden type="submit"></button>
          </c-form>
        </b-card>
      </b-col>
      <b-col v-if="cP(11)" sm="12" md="6">
        <b-card title="Atur ulang kata sandi" class="text-center mt-3">
          <b-card-text>
            <b-button @click="renewConfirm" variant="danger" size="sm">
              <feather-icon icon="RefreshCwIcon"></feather-icon> Atur Ulang Kata Sandi
            </b-button>
            <hr>
            <small>Atur ulang kata sandi dengan menekan tombol diatas dan buat kata sandi baru.</small>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </c-card>
</template>

<script>
import {BCard,BButton,BCardText} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import CButton from '@/utils/components/CButton.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CForm from '@/utils/components/CForm.vue'
import CCard from '@/utils/components/CCard.vue'

export default {
  components:{
    BCard,
    BButton,
    BCardText,
    CButton,
    FormInput,
    CForm,
    CCard
  },
  data(){
    return {
      id: this.$route.params.id,
      isloading: false,
      name:'',
      username:'',
      old_password: '',
      password: '',
      confirmationPass: '',
      is_disable: false,
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'users/changePassword',
    }),
    async getData(){
      const id = this.$route.params.id
      const {data} = await this.$http.get(`v1/users/show`,{params:{id:id}})
      this.username = data.username
      this.name = data.name
    },
    resetForm(){
      this.old_password = ''
      this.password = ''
      this.confirmationPass = ''
      this.is_disable = false
    },
    async submitForm(){
      this.isloading = true
      const {id,old_password,password,username,is_disable} = this
      let params = {id,old_password,username,password}
      params['is_disable'] = is_disable ? 1:0

      try {
        await this.$http.put(`v1/users/changepass`,params,{params:{id,name:username}})
        this.$refs.myform.reset()
        this.notify("Kata sandi berhasil dirubah")
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    renewConfirm(){
      // this.confirmation(`disableButton`,`Anda akan mengatur ulang kata sandi`,`Konfirmasi Atur Ulang Kata Sandi ?`)
      this.confirmation(`disableButton`,{text:`Anda akan mengatur ulang kata sandi`,title:`Konfirmasi Atur Ulang Kata Sandi ?`})
    },
    disableButton(){
      this.is_disable = true
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>